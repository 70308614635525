import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JwtHelperService } from "@auth0/angular-jwt";

import { environment } from 'src/environments/environment';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public host  = environment.apiUrl;
  private token: any;
  private refreshToken: any;
  loggedInUsername: any;
  
  constructor(private http : HttpClient,
    private jwtHelper :  JwtHelperService) { }

  //we need {observe: 'response'} in the request to get the headers, otherwise it will only return the body
  public login(user: User): Observable<HttpResponse<User>> {
    return this.http.post<User>(`${this.host}/user/login`, user, {observe: 'response'});
  }

  public postRefreshToken(request :any): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.host}/user/refresh-token`, request, {observe: 'response'});
  }

  public logOut(): void {
    this.token = null;
    this.loggedInUsername = null;
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('refresh-token');
    localStorage.removeItem('users');
  }
  
  public saveTokenToCache(token : any) : void {
    this.token = token;
    localStorage.setItem('token', token);
  }

  public loadTokenFromCache(): void {
    this.token = localStorage.getItem('token');
  }

  public getToken(): string {
    return this.token;
  }
  
  public saveRefreshTokenToCache(token : any) : void {
    this.refreshToken = token;
    localStorage.setItem('refresh-token', token);
  }
  
  public loadRefreshTokenFromCache(): void {
    this.refreshToken = localStorage.getItem('refresh-token');
  }
  
  public getRefreshToken(): string {
    return this.refreshToken;
  }
  
  //convert object into a string and save on local storage
  public addUserToCache(user : User): void {
    localStorage.setItem('user', JSON.stringify(user));
  }
  
  public getUserFromCache(): User {
    return JSON.parse(localStorage.getItem('user') || '{}');
  }

  public isUserLoggedIn(): boolean {
    
    this.loadTokenFromCache();
    
    if (this.token != null && this.token != ''){
      //jwt .sub is the username
      if (this.jwtHelper.decodeToken(this.token).sub != null || '' ){
        if(!this.jwtHelper.isTokenExpired(this.token)){
          this.loggedInUsername = this.jwtHelper.decodeToken(this.token).sub;
          return true;
        }
      }
    } else {
      this.logOut();
    }
    return false;
  }
    
}
