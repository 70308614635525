import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import PerfectScrollbar from 'perfect-scrollbar';
import { Role } from 'src/app/enum/role.enum';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { UserService } from 'src/app/service/user.service';
import { SubSink } from 'subsink';

declare const $: any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ADMIN_ROUTES: RouteInfo[] = [{
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard'
}, {
    path: '/courses',
    title: 'Courses',
    type: 'link',
    icontype: 'menu_book'
},  {
    path: '/university',
    title: 'Manage University',
    type: 'sub',
    icontype: 'school',
    collapse: 'manage_university',
    children: [
        {path: 'major', title: 'University ', ab:'U'},
        {path: 'create', title: 'Manage University ', ab:'M'}
    ]
}, {
    path: '/user',
    title: 'Manage Users',
    type: 'sub',
    icontype: 'people',
    collapse: 'user',
    children: [
        {path: 'all-users', title: 'All Users', ab:'U'},
        {path: 'admin', title: 'Super Admin / Admin', ab:'SU'},
        {path: 'students', title: 'Students', ab:'S'},
        {path: 'professors', title: 'Professors', ab:'P'},
        {path: 'guardian', title: 'Guardian', ab:'G'},
    ]
},
{
    path: '/meet',
    title: 'Meet',
    type: 'link',
    icontype: 'meeting_room'
},  {
    path: '/grade-books',
    title: 'Gradebook',
    type: 'link',
    icontype: 'description'
},  {
    path: '/news',
    title: 'News',
    type: 'link',
    icontype: 'newspaper'
},  {
    path: '/email',
    title: 'E-mail',
    type: 'link',
    icontype: 'email'
},  {
    path: '/forum',
    title: 'Forum',
    type: 'link',
    icontype: 'forum'
}
];

export const PROFESSOR_ROUTES: RouteInfo[] = [{
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard'
}, {
    path: '/courses',
    title: 'Courses',
    type: 'link',
    icontype: 'school'
},  {
    path: '/grade-books',
    title: 'Gradebook',
    type: 'link',
    icontype: 'grading'
},  {
    path: '/meet',
    title: 'Meet Now',
    type: 'link',
    icontype: 'groups'
}, {
    path: '/calendar',
    title: 'Calendar',
    type: 'link',
    icontype: 'today'
},{
    path: '/news',
    title: 'News',
    type: 'link',
    icontype: 'article'
},  {
    path: '/email',
    title: 'E-mail',
    type: 'link',
    icontype: 'email'
},  {
    path: '/forum',
    title: 'Forum',
    type: 'link',
    icontype: 'forum'
}
];

export const STUDENT_ROUTES: RouteInfo[] = [{
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard'
}, {
    path: '/courses',
    title: 'Courses',
    type: 'link',
    icontype: 'school'
},  {
    path: '/grade-book',
    title: 'Gradebook',
    type: 'link',
    icontype: 'grading'
},  {
    path: '/meet',
    title: 'Meet Now',
    type: 'link',
    icontype: 'groups'
},  {
    path: '/calendar',
    title: 'Calendar',
    type: 'link',
    icontype: 'today'
},  {
    path: '/news',
    title: 'News',
    type: 'link',
    icontype: 'article'
},  {
    path: '/email',
    title: 'E-mail',
    type: 'link',
    icontype: 'email'
},  {
    path: '/forum',
    title: 'Forum',
    type: 'link',
    icontype: 'forum'
}
];

@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit, OnDestroy {
    public menuItems: any[];
    ps: any;

    private subs = new SubSink();

    username : string;

    profileImageUrl: any;
    
    constructor(private authenticationService : AuthenticationService,
        private userService : UserService,
        private router : Router) {}

    ngOnDestroy(): void {
        this.subs.unsubscribe()
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    ngOnInit() {

        const user = this.authenticationService.getUserFromCache()
        const userRole = user.role;
        
        this.username = `${user.firstName} ${user.lastName}`;
        
        switch(userRole) { 
            case Role.SUPER_ADMIN: { 
                this.menuItems = ADMIN_ROUTES.filter(menuItem => menuItem);
               break; 
            } 
            case Role.ADMIN: { 
                this.menuItems = ADMIN_ROUTES.filter(menuItem => menuItem);
               break; 
            } 
            case Role.PROFESSOR: { 
                this.menuItems = PROFESSOR_ROUTES.filter(menuItem => menuItem);
               break; 
            } 
            case Role.GUARDIAN: { 
                this.menuItems = ADMIN_ROUTES.filter(menuItem => menuItem);
               break; 
            } 
            case Role.STUDENT: { 
                this.menuItems = STUDENT_ROUTES.filter(menuItem => menuItem);
               break; 
            } 
            default: { 
                
               break; 
            } 
         } 
        
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }

        this.subs.add(
            this.userService.getProfilePicture(this.authenticationService.getUserFromCache().username).subscribe(
                response => {
                    console.log(response)
                    this.createImageFromBlob(response)
                }, error => {
                    this.profileImageUrl = "/assets/img/default-avatar.png"

                }
            )
        )
    }

    createImageFromBlob(image: Blob) {
        let reader = new FileReader();
        if (image) {
           reader.readAsDataURL(image);
        }
        reader.addEventListener("load", () => {
           this.profileImageUrl = reader.result;
        }, false);
     }


    updatePS(): void  {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
    onLogout() {
        this.authenticationService.logOut();   
        this.router.navigateByUrl('/pages/login')
    }
}
