import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(private http: HttpClient) { }

  getAssignmentsByDeadline (year : any, month : any, id : any, type : any): Observable<any>{
    return this.http.get(environment.apiUrl + `/api/university/calendar/deadlines/${year}/${month}/${id}/${type}`)
  }
}
