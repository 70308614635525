import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GradeBookService {

  constructor(private http: HttpClient) { }

  getAssignmentGradesByStudent (studentId : any): Observable<any>{
    return this.http.get(environment.apiUrl + `/api/university/grade-book/${studentId}`)
  }
  getAssignmentsByCourseIdAndStudentId (courseId: any, studentId : any): Observable<any>{
    return this.http.get(environment.apiUrl + `/api/university/grade-book/${studentId}/course/${courseId}`)
  }
  
}
