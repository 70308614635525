import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';


import { NotificationType } from '../enum/notification-type.enum';
import { AuthenticationService } from '../service/authentication.service';
import { NotificationService } from '../service/notification.service';
import { HeaderType } from '../enum/header-type.enum';

const TOKEN_HEADER_KEY = 'Authorization';  

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authenticationService : AuthenticationService,
    private router : Router,
    private notificationService : NotificationService) {}

  intercept(httpRequest: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {

    if (httpRequest.url.includes(`${this.authenticationService.host}/user/login`)){
      return httpHandler.handle(httpRequest);
    }
    if (httpRequest.url.includes(`${this.authenticationService.host}/user/resetPassword`)){
      return httpHandler.handle(httpRequest);
    }
    if (httpRequest.url.includes(`${this.authenticationService.host}/user/refresh-token`)){
      return httpHandler.handle(httpRequest);
    }
    
    let authReq = httpRequest;
    this.authenticationService.loadTokenFromCache();
    const token = this.authenticationService.getToken();

    if (token != null) {
      authReq = this.addTokenHeader(httpRequest, token);
    }

    return httpHandler.handle(authReq).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        this.handle401Error(authReq, httpHandler);
      }

      return throwError(error);
    }));

  }
  
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    this.authenticationService.loadRefreshTokenFromCache();
    const refreshToken = this.authenticationService.getRefreshToken();
    const username = this.authenticationService.getUserFromCache().username;

    const requestBody = {
      refreshToken : refreshToken,
      username : username
    }
    this.authenticationService.postRefreshToken(requestBody).subscribe(
      (response : HttpResponse<any>) => {
        console.log(response)
        const token = response.headers.get(HeaderType.JWT_TOKEN);
        const refreshToken = response.headers.get(HeaderType.JWT_REFRESH_TOKEN);
        this.authenticationService.saveTokenToCache(token);
        this.authenticationService.saveRefreshTokenToCache(refreshToken);
        this.notificationService.notify(NotificationType.INFO, `Session reactivated!`)
      }, error => {
        console.log(error)
        this.router.navigate(['/page/login']);
        this.notificationService.notify(NotificationType.ERROR, `Session inactive!`)
      }
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: any) {
    return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
  }

}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];