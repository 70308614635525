import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationType } from 'src/app/enum/notification-type.enum';
import { NotificationService } from 'src/app/service/notification.service';
import { CourseGradeService } from 'src/app/service/university/course-grade.service';
import { GradeBookService } from 'src/app/service/university/grade-book.service';
import { StudentService } from 'src/app/service/university/student.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-grade-books',
  templateUrl: './grade-books.component.html',
  styleUrls: ['./grade-books.component.css']
})
export class GradeBooksComponent implements OnInit {

  private subs = new SubSink();

  studentSearchForm !: FormGroup;

  // pagination 
  thePageNumber : number = 1;
  thePageSize : number = 10;
  totalElements : number = 0 ;
  totalPages : number;
  pageNumbers : number [];

  students : any;
  studentGrade : any;

  modalRef: BsModalRef;
  
  courseId: any;
  selectedStudent: any;
  gradeBooks: any;

  constructor(private formBuilder: FormBuilder,
    private studentService : StudentService,
    private gradeBookService : GradeBookService,
    private courseGradeService : CourseGradeService,
    private route : ActivatedRoute,
    private modalService: BsModalService,
    private notificationService : NotificationService,
    private spinner : NgxSpinnerService) { }

  ngOnInit(): void {
    this.route.parent.params.subscribe(params => {
      this.courseId = params.id;
    })

    this.studentSearchForm = this.formBuilder.group({
      studentId : new FormControl(null, [Validators.required]),
      firstName : new FormControl(null, [Validators.required]),
      lastName : new FormControl(null, [Validators.required]),
      batchYear : new FormControl(null, [Validators.required]),
      batchNumber : new FormControl(null, [Validators.required])
    })

    this.getStudentsPage();
  }

  getStudentsPage() {
    
    const pageableRequest = {
      pageableRequest : {
        pageNumber  : this.thePageNumber - 1,
        sizeOfPage  : this.thePageSize,
        sort  : "id"  
      },
      studentSearchCriteria : this.studentSearchForm.value
    }

    this.spinner.show()

    this.subs.add(
      this.studentService.page(pageableRequest).subscribe(
        ( response : any ) => {
          this.spinner.hide()

          this.students = [];

          this.students = response.content
          console.log(this.students)
          

          if(this.students?.content?.length === 0) {
            this.sendErrorNotification(NotificationType.WARNING, "No students were assigned to the following course!")
            return;
          }
          this.totalPages = response?.totalPages;
          this.totalElements = response?.totalElements;
        }, error => {
            console.log(error)
            this.sendErrorNotification(NotificationType.ERROR, error?.error?.message)
            this.spinner.hide()
          }
      )
    )

  }

  onSearch() {

    this.thePageNumber = 1;

    if(this.studentSearchForm.get('studentId').value === "") {
      this.studentSearchForm.get('studentId').patchValue(null);
    }
    if(this.studentSearchForm.get('firstName').value === "") {
      this.studentSearchForm.get('firstName').patchValue(null);
    }
    if(this.studentSearchForm.get('lastName').value === "") {
      this.studentSearchForm.get('lastName').patchValue(null);
    }
    if(this.studentSearchForm.get('batchYear').value === "") {
      this.studentSearchForm.get('batchYear').patchValue(null);
    }
    if(this.studentSearchForm.get('batchNumber').value === "") {
      this.studentSearchForm.get('batchNumber').patchValue(null);
    }
    console.log(this.studentSearchForm.value)

    this.getStudentsPage()
  }

  setSelectedStudent(student : any) {
    console.log(student)
    this.selectedStudent = student;
    
    this.gradeBooks = "";
    
    this.spinner.show()
    this.subs.add(
      this.gradeBookService.getAssignmentGradesByStudent(this.selectedStudent.id).subscribe(
        response => {
          this.spinner.hide();
          if(response?.length === 0 ) {
            this.sendErrorNotification(NotificationType.WARNING, 'This Student is not assigned to any Course')
            this.modalRef.hide()
          }
          if(response)
          this.gradeBooks = response;

        },(error : HttpErrorResponse) => {
          console.log(error)
          this.spinner.hide()
          this.sendErrorNotification(NotificationType.ERROR, error.error.message)
        }
      )
    )
  }
  
  onChangePageSize( event : any ) {
    this.thePageNumber = 1 ;
    this.thePageSize = event
    this.getStudentsPage()
  }

  pageChanged(event : any ) {
    this.thePageNumber = event;
    this.getStudentsPage()
  }

  openModal(template: TemplateRef<any>, configType : string) {
    const config: ModalOptions = { class: configType };
    this.modalRef = this.modalService.show(template, config);
  }

  private sendErrorNotification(notificationType : NotificationType, message: string) {
    if( message ) {
      this.notificationService.notify(notificationType, message)
    } else {
      this.notificationService.notify(notificationType, 'An error occurred, Please try again!')
    }
  }

}
