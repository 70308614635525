import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CourseGradeService {

  constructor(private http: HttpClient) { }

  create (courseGrade : any): Observable<any>{
    return this.http.post(environment.apiUrl + `/api/university/course-grade`, courseGrade)
  }
  update (courseGrade : any): Observable<any>{
    return this.http.put(environment.apiUrl + `/api/university/course-grade`, courseGrade)
  }
}
