<div class="main-content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header card-header-icon card-header-rose">
                <div class="card-icon">
                    <i class="material-icons">receipt</i>
                </div>
                <h4 class="card-title">Grade Book</h4>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12" *ngIf="gradeBooks?.length != 0">
                        <div *ngFor="let gradeBook of gradeBooks">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">{{gradeBook?.name}}</h4>

                                </div>
                                <div class="card-body">
                                    <div class="d-flex flex-column" *ngFor="let section of gradeBook?.courseSection">
                                        <div class="d-flex flex-column" >
                                            <label class="form-control-label">{{ section.name }}</label>
                                            <div class="d-flex flex-column" *ngIf="section.assignments?.length === 0">
                                                <p class=" pl-5 h6 text-muted">{{"No assignments Available "}}</p>
                                                
                                            </div>
                                            <div class="d-flex flex-column pl-5" *ngIf="section.assignments?.length != 0 ">
                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <thead class="thead-light">
                                                            <tr>
                                                              <th scope="col">Assignment Name</th>
                                                              <th scope="col">Submission</th>
                                                              <th scope="col">Grade</th>
                                                              
                                                              
                                                            </tr>
                                                        </thead>
                                                          <tbody>
                                                              <tr *ngFor="let assignment of section.assignments">
                                                                  <td>{{assignment.assignmentName}}</td>
                                                                  <td>{{ assignment.submission != null ? 'Submitted' : 'No Submission' }}</td>
                                                                  <td>{{assignment.submission?.grade != null ? assignment.submission?.grade : 'Pending' }}</td>
                                                              </tr>
                                                            
                                                          </tbody>
                                                    </table>
                                                    
                                                </div> 

                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                    <p class="bg-light p-2 h5 mt-4 text-primary">{{"Course Grade : "}}{{gradeBook?.courseGradeDto?.grade != null ? gradeBook.courseGradeDto?.grade : 'Not Graded'}}</p>
                                    

                                </div>
                                
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner 
    bdColor = "rgba(0, 0, 0, 0.8)" 
    size = "medium" 
    color = "#fff" 
    type = "ball-atom" [fullScreen] = "true">
</ngx-spinner>