import { AfterViewInit, Component, forwardRef, OnInit, ViewChild } from '@angular/core';
import { CalendarOptions, Calendar } from '@fullcalendar/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import interactionPlugin from '@fullcalendar/interaction';
import { SubSink } from 'subsink';
import { CalendarService } from 'src/app/service/university/calendar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/service/notification.service';
import { NotificationType } from 'src/app/enum/notification-type.enum';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

  private subs = new SubSink();

  calendarOptions !: CalendarOptions;
  calendarEvents : any = [] ;
  selectedEvent: any;
  
  @ViewChild('fullcalendar') fullcalendar : FullCalendarComponent ;
  
  constructor(private calendarService : CalendarService,
    private spinner :NgxSpinnerService,
    private authService: AuthenticationService,
    private notificationService : NotificationService) { }
  
  ngOnInit(): void {

    // need for load calendar bundle first
    forwardRef(() => Calendar);

    this.calendarOptions = {
      plugins: [dayGridPlugin, interactionPlugin, bootstrapPlugin ],
      editable: false,
      
      customButtons: {
        next: {
            click: this.nextMonth.bind(this),
        },
        prev: {
            click: this.prevMonth.bind(this),
        },
        today: {
            text : 'Today',
            click: this.today.bind(this),
        },
      },
      events : this.calendarEvents,
      eventClick: this.handleEventClick.bind(this)
    }; 
      

    setTimeout(() => {
      console.log('setTimeout')
      this.getEvents()
    }, 1000);
    
  }

  getEvents() {
    this.calendarEvents = [];
    const dates = this.generateDates();
    console.log(dates)
    const user = this.authService.getUserFromCache()

    let id;

    if(user.role === 'ROLE_PROFESSOR') {
      id = user?.professor?.id
    } else if (user.role === 'ROLE_STUDENT') {
      id = user?.student?.id
    }
    
    this.subs.add(
      this.calendarService.getAssignmentsByDeadline(dates.year, dates.month, id,user.role).subscribe(
        response => {
          console.log(response)
          response.forEach(element => {
            var color = Math.floor(0x1000000 * Math.random()).toString(16);
            const colorCode = '#' + ('000000' + color).slice(-6);
            
            const event = {
              title : element.assignmentName,
              start : new Date(element.deadline),
              end: new Date(element.deadline),
              color : colorCode,
              allDay : true
            }

            this.calendarEvents = [...this.calendarEvents , event]
          });
          this.calendarOptions.events = this.calendarEvents;

        }, ( error : HttpErrorResponse ) => {
          console.log(error)
          this.sendErrorNotification(NotificationType.ERROR, error?.error?.message)
          this.spinner.hide()
        }
      )
    )

    
  }

  nextMonth() {

    const calendarApi = this.fullcalendar.getApi();
    calendarApi.next();
    this.getEvents()
  }
  
  prevMonth() {
    const calendarApi = this.fullcalendar.getApi();
    calendarApi.prev();
    this.getEvents()
    
  }

  today () {
    const calendarApi = this.fullcalendar.getApi();
    calendarApi.today();
    this.getEvents()
  }

  handleEventClick(arg: any) {
    this.selectedEvent = arg.event.extendedProps;
    console.log(this.selectedEvent )
  }

  generateDates() {
    const calendarApi = this.fullcalendar.getApi();
    
    const year = calendarApi.view.currentEnd.getUTCFullYear()
    const month = calendarApi.view.currentEnd.getUTCMonth() 
   
    
    const dates = {
      year : year,
      month : month
    }

    return dates;
  }

  private sendErrorNotification(notificationType : NotificationType, message: string) {
    if( message ) {
      this.notificationService.notify(notificationType, message)
    } else {
      this.notificationService.notify(notificationType, 'An error occurred, Please try again!')
    }
  }

}
