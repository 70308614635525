<footer class="footer ">
  <div class="container-fluid">
    <nav class="pull-left">
      <ul>
        <li>
          <a href="#">
            Lexi Coporation
          </a>
        </li>
        <li>
          <a href="#">
            About Us
          </a>
        </li>
        <li>
          <a href="#">
            Licenses
          </a>
        </li>
      </ul>
    </nav>
    <div class="copyright pull-right">
      &copy;
      {{test | date: 'yyyy'}}, made by
      <a href="#" target="_blank">Lexi</a> for a better education system.
    </div>
  </div>
</footer>
