import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationType } from 'src/app/enum/notification-type.enum';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { NotificationService } from 'src/app/service/notification.service';
import { GradeBookService } from 'src/app/service/university/grade-book.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-student-grade-book',
  templateUrl: './student-grade-book.component.html',
  styleUrls: ['./student-grade-book.component.css']
})
export class StudentGradeBookComponent implements OnInit, OnDestroy {

  private subs = new SubSink();
  
  studentId: any;
  gradeBooks: any[] = [];

  constructor(private gradeBookService : GradeBookService,
    private spinner : NgxSpinnerService,
    private route : ActivatedRoute,
    private authService : AuthenticationService,
    private notificationService : NotificationService) { }


  ngOnInit(): void {
    

    this.studentId = this.authService.getUserFromCache()?.student.id;
    console.log(this.studentId)

    this.getGradeBook();
  }

  getGradeBook() {
    this.spinner.show()
    this.subs.add(
      this.gradeBookService.getAssignmentGradesByStudent(this.studentId).subscribe(
        response => {
          this.spinner.hide();
          console.log(response)
          this.gradeBooks = response;
          
        },(error : HttpErrorResponse) => {
          console.log(error)
          this.spinner.hide()
          this.sendErrorNotification(NotificationType.ERROR, error.error.message)
        }
      )
    )
  }

  private sendErrorNotification(notificationType : NotificationType, message: string) {
    if( message ) {
      this.notificationService.notify(notificationType, message)
    } else {
      this.notificationService.notify(notificationType, 'An error occurred, Please try again!')
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

}
