<div class="main-content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header card-header-icon card-header-rose">
              <div class="card-icon">
                <i class="material-icons">calendar_month</i>
              </div>
              <h4 class="card-title">Calendar</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                    <full-calendar  *ngIf="calendarOptions" deepChangeDetection="true"  #fullcalendar [options]="calendarOptions"></full-calendar>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner 
      bdColor = "rgba(0, 0, 0, 0.8)" 
      size = "medium" 
      color = "#fff" 
      type = "ball-atom" [fullScreen] = "true">
</ngx-spinner>
