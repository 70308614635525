<router-outlet></router-outlet>
<div style="height: 0; overflow: hidden;">
    <notifier-container></notifier-container>
</div>


<ngx-spinner 
      bdColor = "rgba(0, 0, 0, 0.8)" 
      size = "medium" 
      color = "#fff" 
      type = "ball-atom" [fullScreen] = "true">
</ngx-spinner>


<!-- test -->
