<div class="main-content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header card-header-icon card-header-rose">
                <div class="card-icon">
                    <i class="material-icons">receipt</i>
                </div>
                <h4 class="card-title">Student Grades</h4>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <form [formGroup]="studentSearchForm" (ngSubmit)="onSearch()">
                            <div class="row mb-2">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="form-control-label">Student Id</label>
                                        <input type="text" class="form-control" formControlName="studentId">                
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="form-control-label">First Name</label>
                                        <input type="text" class="form-control" formControlName="firstName">                
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="form-control-label">Last Name</label>
                                        <input type="text" class="form-control" formControlName="lastName">                
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="form-control-label">Batch Year</label>
                                        <input type="text" class="form-control" formControlName="batchYear">                
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="form-control-label">Batch Number</label>
                                        <input type="text" class="form-control" formControlName="batchNumber">                
                                    </div>
                                </div>
                                <div class="col-md-12 d-flex justify-content-end">
                                    <button type="submit" class="btn btn-default">Search</button>
                                </div>
                            </div>
                            
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive" *ngIf="students?.length != 0">
                            <table class="table">
                                    <thead class="thead-light">
                                        <tr>
                                          <th scope="col">Student Id</th>
                                          <th scope="col">First Name</th>
                                          <th scope="col">Last Name</th>
                                          <th scope="col">Batch Year</th>
                                          <th scope="col">Batch Number</th>
                                          <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                  <tbody>
                                      <tr *ngFor="let student of students | paginate: { itemsPerPage: thePageSize , currentPage: thePageNumber, totalItems : totalElements }; index as i">
                                          <td class="text-wrap">{{student.studentId}}</td>
                                          <td class="text-wrap">{{student.firstName}}</td>
                                          <td class="text-wrap">{{student.lastName}}</td>
                                          <td class="text-wrap">{{student.batchYear}}</td>
                                          <td class="text-wrap">{{student.batchNumber}}</td>
                                          <td class="td-actions text-nowrapd">
                                            <button type="button" rel="tooltip" class="mat-raised-button mat-button-base btn btn-primary" (click)="openModal(courseGrade, 'modal-xl'); setSelectedStudent(student)">
                                                <i class="material-icons">visibility</i>
                                            </button>
                                          </td>
                                      </tr>
                                  </tbody>
                            </table>
                        </div> 
                        <div *ngIf="students?.length === 0">
                            <label class="form-control-label">No Students Assigned to this Course.</label>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="container-fluid p-0" *ngIf="students?.length != 0">
                            <div class="row">
                                <div class="col-md-3 mt-3 d-flex flex-row">
                                    <label class="form-control-label m-0 mr-4 align-middle text-nowrap">{{"Page Size"}}</label>
                                    <mat-select placeholder="Size" style="max-width: 60px !important; "
                                            (ngModelChange)="onChangePageSize($event)"
                                        [ngModel]="thePageSize">
                                        <mat-option [value]="5">5</mat-option>
                                        <mat-option [value]="10">10</mat-option>
                                        <mat-option [value]="25">25</mat-option>
                                        <mat-option [value]="50">50</mat-option>
                                      </mat-select>
                                </div>
                                <div class="col-md-9 d-flex justify-content-end align-items-end">
                                    <pagination-controls class="my-pagination d-flex justify-content-end" previousLabel="Prev"
                                        nextLabel="Next"
                                        responsive="true" 
                                        maxSize="6"
                                        directionLinks="true"
                                        autoHide="true"
                                        (pageChange)="pageChanged($event)"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>



<ng-template #courseGrade>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Student Grade Book</h4>
      <button id="addSectionClose" type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12" *ngIf="gradeBooks?.length != 0">
                <div *ngFor="let gradeBook of gradeBooks">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">{{gradeBook?.name}}</h4>

                        </div>
                        <div class="card-body">
                            <div class="d-flex flex-column" *ngFor="let section of gradeBook?.courseSection">
                                <div class="d-flex flex-column" >
                                    <label class="form-control-label">{{ section.name }}</label>
                                    <div class="d-flex flex-column" *ngIf="section.assignments?.length === 0">
                                        <p class=" pl-5 h6 text-muted">{{"No assignments Available "}}</p>
                                        
                                    </div>
                                    <div class="d-flex flex-column pl-5" *ngIf="section.assignments?.length != 0 ">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead class="thead-light">
                                                    <tr>
                                                      <th scope="col">Assignment Name</th>
                                                      <th scope="col">Submission</th>
                                                      <th scope="col">Grade</th>
                                                      
                                                      
                                                    </tr>
                                                </thead>
                                                  <tbody>
                                                      <tr *ngFor="let assignment of section.assignments">
                                                          <td>{{assignment.assignmentName}}</td>
                                                          <td>{{ assignment.submission != null ? 'Submitted' : 'No Submission' }}</td>
                                                          <td>{{assignment.submission?.grade != null ? assignment.submission?.grade : 'Pending' }}</td>
                                                      </tr>
                                                    
                                                  </tbody>
                                            </table>
                                            
                                        </div> 

                                    </div>
                                </div>
                                
                            </div>
                            
                            <p class="bg-light p-2 h5 mt-4 text-primary">{{"Course Grade : "}}{{gradeBook?.courseGradeDto?.grade != null ? gradeBook.courseGradeDto?.grade : 'Not Graded'}}</p>
                            

                        </div>
                        
                    </div>
                </div>
                
                
            </div>
        </div>
                
        
    </div>
  </ng-template>