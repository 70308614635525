<div class="main-content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header card-header-icon card-header-rose">
              <div class="card-icon">
                <i class="material-icons">newspaper</i>
              </div>
              <h4 class="card-title">News</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 text-center">
                  <p class="h3 text-warning">This Feature is disabled in Demo Mode</p>
                  <img src="/assets/img/under_construction.png"  alt="under construction" class="img-fluid" style="max-width: 220px; height: auto;">
                </div>
              </div>
            </div>
        </div>
    </div>
</div><span class="material-symbols-outlined">
newspaper
</span>