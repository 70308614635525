import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { CustomHttpResponse } from '../model/custom-http-response';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private host  = environment.apiUrl;

  constructor(private http : HttpClient) { }

  public findAll(): Observable<User[]> {
    return this.http.get<User []>(`${this.host}/user/list`);
  }

  public page(pageable: any): Observable<any> {
    return this.http.post(`${this.host}/user/page`, pageable);
  }

  public create(formData : FormData): Observable<User> {
    return this.http.post<User>(`${this.host}/user/add`, formData);
  }

  public createMulti(formData : FormData): Observable<any> {
    return this.http.post<any>(`${this.host}/user/add-multi`, formData);
  }

  public update(formData : FormData): Observable<User> {
    return this.http.post<User>(`${this.host}/user/update`, formData);
  }
  
  public get (id : number): Observable<User> {
    return this.http.get<User>(`${this.host}/user/${id}`);
  }
  
  public delete(userId : number): Observable<CustomHttpResponse>{
    return this.http.delete<CustomHttpResponse>(`${this.host}/user/delete/${userId}`);
  }

  public getProfilePicture(username : string): Observable<Blob>{
    return this.http.get(`${this.host}/user/image/${username}/${username}.jpg`, { responseType: 'blob' });
  }

  public addUsersToCache(users : User[]): void {
    localStorage.setItem('users', JSON.stringify(users));
  }
  
  public getUsersFromCache(): User[] | null {
    if (localStorage.getItem('users')){
      return JSON.parse(localStorage.getItem('users') || '{}');
    }
    return null;
  }

  public createUserFormData(loggedInUsername : string | null, user: User): FormData {
    const formData = new FormData();
    formData.append('currentUsername', loggedInUsername!);
    formData.append('firstName', user.firstName);
    formData.append('lastName', user.lastName);
    formData.append('username', user.username);
    formData.append('description', user.description);
    formData.append('email', user.email);
    formData.append('role', user.role);
    formData.append('active', JSON.stringify(user.active) || 'false');
    formData.append('notLocked', JSON.stringify(user.notLocked) || 'false');
    
    return formData;

  }

  public resetPassword(email : string): Observable<CustomHttpResponse> {
    return this.http.get<CustomHttpResponse>(`${this.host}/user/resetPassword/${email}`);
  }
  
  public updateProfileImage(formData : FormData): Observable<HttpEvent<User>> {
    return this.http.post<User>(`${this.host}/user/updateProfileImage`, formData, 
        { reportProgress: true, 
          observe : 'events'
        });
  }

  public getProfileImageUrl() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    console.log(user)
    return user.profileImageUrl;
  }
}
