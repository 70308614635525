import { Routes } from '@angular/router';
import { CalendarComponent } from './components/application/calendar/calendar.component';
import { DashboardComponent } from './components/application/dashboard/dashboard.component';
import { EMailComponent } from './components/application/e-mail/e-mail.component';
import { ForumComponent } from './components/application/forum/forum.component';
import { GradeBooksComponent } from './components/application/grade-book/grade-books/grade-books.component';
import { StudentGradeBookComponent } from './components/application/grade-book/student-grade-book/student-grade-book.component';
import { MeetComponent } from './components/application/meet/meet.component';
import { NewsComponent } from './components/application/news/news.component';
import { AuthenticationGuard } from './guard/authentication.guard';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

export const AppRoutes: Routes = [
  {
    path: '', redirectTo: 'pages/login', pathMatch: 'full',
  }, {
    path: '', component: AdminLayoutComponent, canActivate: [AuthenticationGuard] ,
      children: [
        { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
        { path: 'dashboard', component: DashboardComponent , data: { breadcrumb: 'Dashboard' }},
        { path: 'courses', loadChildren : './components/application/course/courses.module#CoursesModule', data: { breadcrumb: 'Course' } }, 
        { path: 'user', loadChildren : './components/application/user/user.module#UserModule' , data: { breadcrumb: { skip: true} } },
        { path: 'university', loadChildren : './components/application/university/university.module#UniversityModule', data: { breadcrumb: 'University' }},
        { path: 'meet', component: MeetComponent, data: { breadcrumb: 'Meet' }},
        { path: 'news', component: NewsComponent, data: { breadcrumb: 'News' }},
        { path: 'email', component: EMailComponent, data: { breadcrumb: 'E-Mail' }},
        { path: 'forum', component: ForumComponent, data: { breadcrumb: 'Forum' }},
        { path: 'grade-book', component: StudentGradeBookComponent, data: { breadcrumb: 'Grade Book' }},
        { path: 'grade-books', component: GradeBooksComponent, data: { breadcrumb: 'Grade Book' }},
        { path: 'calendar', component: CalendarComponent, data: { breadcrumb: 'Calendar' }}
      ]
  }, {
    path: '', component: AuthLayoutComponent,
      children: [
        { path: 'pages', loadChildren: './components/shared/pages/pages.module#PagesModule'}
      ]
  }
];